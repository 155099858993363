//
// Layout Initialization
//

// Import Dependencies
@import "../core/layout/base";
@import "print";
@import "main";
@import "header";
@import "toolbar";
@import "aside/base";
@import "aside/menu";
@import "content";
@import "footer";

//for delete,reload,sort,active,inactive buttons hover state
.dt-btn-secondary:hover,.dt-btn-outline-primary:hover{
    color:white!important;
}
