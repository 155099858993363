//
// Table
//

// Table
.table {

	// Fix for BS 5.1.2 update
	&> :not(:first-child) {
		border-color: transparent;
		border-width: 0;
		border-style: none;
	}

	>:not(:last-child)>:last-child>* {
		border-bottom-color: inherit;
	}

	tr,
	th,
	td {
		border-color: inherit;
		border-width: inherit;
		border-style: inherit;
		text-transform: inherit;
		font-weight: inherit;
		font-size: inherit;
		color: inherit;
		height: inherit;
		min-height: inherit;

		&:first-child {
			// padding-left: 0;
		}

		&:last-child {
			// padding-right: 0;
		}
	}

	tfoot,
	tbody {
		tr:last-child {
			border-bottom: 0 !important;

			th,
			td {
				border-bottom: 0 !important;
			}
		}
	}

	tfoot {

		th,
		td {
			border-top: inherit;
		}
	}

	// Rounded
	&.table-rounded {
		border-radius: $border-radius;
		border-spacing: 0;
		border-collapse: separate;
	}

	// Flush
	&.table-flush {

		tr,
		th,
		td {
			padding: inherit;
		}
	}

	// Row bordered
	&.table-row-bordered {
		tr {
			border-bottom-width: 1px;
			border-bottom-style: solid;
			border-bottom-color: $border-color;
		}

		tfoot {

			th,
			td {
				border-top-width: 1px !important;
			}
		}
	}

	// Row dashed
	&.table-row-dashed {
		tr {
			border-bottom-width: 1px;
			border-bottom-style: dashed;
			border-bottom-color: $border-color;
		}

		tfoot {

			th,
			td {
				border-top-width: 1px !important;
			}
		}
	}

	// Row border colors
	@each $color,
	$value in $grays {
		&.table-row-gray-#{$color} {
			tr {
				border-bottom-color: $value;
			}
		}
	}
}

// Cell gutters
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		@each $name,
		$value in $gutters {
			.table.g#{$infix}-#{$name} {

				th,
				td {
					padding: $value;

					// Datatables responsive mode fix
					&.dtr-control {
						padding-left: $value !important;
					}
				}
			}

			.table.gy#{$infix}-#{$name} {

				th,
				td {
					padding-top: $value;
					padding-bottom: $value;
				}
			}

			.table.gx#{$infix}-#{$name} {

				th,
				td {
					padding-left: $value;
					padding-right: $value;

					// Datatables responsive mode fix
					&.dtr-control {
						padding-left: $value !important;
					}
				}
			}

			.table.gs#{$infix}-#{$name} {

				th,
				td {
					&:first-child {
						// padding-left: $value;
					}

					&:last-child {
						// padding-right: $value;
					}

					// Datatables responsive mode fix
					&.dtr-control:first-child {
						padding-left: $value !important;
					}
				}
			}
		}
	}
}