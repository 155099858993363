.fileManager {
  .folder {
    text-align: center;
    cursor: pointer;
    height: 165px;
    &:hover {
      background-color: rgb(231 231 231);
    }
    &.active {
      background-color: #fbd6d6;
    }
    .icon {
      font-size: 80px;
      position: relative;
      .badge {
        position: absolute;
        top: 20px;
        left: 30px;
        padding: 0px;
        .rs-badge {
          padding: 0px 12px;
          font-size: 10px;
        }
      }
      img {
        height: 80px;
        width: 80px;
        object-fit: cover;
        object-position: center;
      }
    }
    .folderName {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 100px;
      margin: auto;
    }
  }
}

.context-menu {
  width: 180px;
  position: absolute;
  background: rgb(255, 255, 255);
  box-shadow: rgb(202 201 201) 3px 3px 3px;
  .rc-option {
    cursor: pointer;
    font-size: 15px;
    padding: 5px 10px;
    &.rc-option-border {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }
    &:hover {
      background-color: rgb(231 231 231);
    }
    svg {
      margin-right: 5px;
    }
  }
}

.preview-modal {
  height: 100%;
  width: 100%;
  .preview-image {
    height: 500px;
    width: 100%;
    object-fit: contain;
  }
}

.info-modal {
  width: 500px !important;
  .preview-info {
    .info-details {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      span {
        font-weight: bold;
        padding-right: 8px;
        width: 15%;
        display: block;
        text-align: right;
      }
      div {
        width: 85%;
      }
    }
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.text-dark {
  color: #181c32 !important;
}

.dropzone.dropzone-queue {
  border: 0;
  padding: 0;
  background-color: transparent;
  text-align: left;
  .dropzone-item {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    border-radius: 0.475rem;
    padding: 0.5rem 1rem;
    background-color: #f5f8fa;
    .dropzone-file {
      flex-grow: 1;
      .dropzone-filename {
        font-size: 0.9rem;
        font-weight: 600;
        color: #7e8299;
        text-overflow: ellipsis;
        margin-right: 0.5rem;
      }
    }
    .dropzone-error {
      margin-top: 0.25rem;
      font-size: 0.9rem;
      font-weight: 400;
      color: #f1416c;
      text-overflow: ellipsis;
    }
    .dropzone-progress {
      width: 15%;
      .progress {
        height: 5px;
        transition: all 0.2s ease;
      }
    }
    .dropzone-toolbar {
      margin-left: 1rem;
      display: flex;
      flex-wrap: nowrap;
      .dropzone-start,
      .dropzone-cancel,
      .dropzone-delete {
        height: 25px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: color 0.2s ease, background-color 0.2s ease;
      }
      .dropzone-start {
        transition: color 0.2s ease, background-color 0.2s ease;
        i {
          transition: color 0.2s ease, background-color 0.2s ease;
          font-size: 0.8rem;
          color: #7e8299;
        }
      }
    }
  }
}

.card-toolbar-main {
  position: relative;
  .card-add-folder {
    position: absolute;
  }
}

.rs-modal-wrapper {
  z-index: 9999;
}

.rs-tree-folderTree {
  background: #fbfbfb;
  overflow-y: auto;
  overflow-x: hidden;
}

.file-top-header-parent {
  button {
    background-color: transparent;
  }
  .file-top-header {
    margin-right: 20px;
    cursor: pointer;
    svg {
      height: 17px;
      width: 17px;
      &.disabled {
        color: #cecbcb;
      }
    }
  }
}

.tox-tinymce-aux {
  z-index: 9999 !important;
}

.Toastify__toast-container--top-right {
  z-index: 99999;
}
